import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
import React, { useState, useEffect } from "react";
import { useMediaQuery } from "react-responsive";
import styled from "styled-components/macro";
import Home from "../Pages/Home.js";
import Page2 from "../Pages/Page2.js"; // This can be any page
import SignIn from "../Pages/SignIn.js";
import Header from "../Components/Header.js";
import * as GlobalVariables from "../Styles/GlobalVariables";

import LoginRedirect from "./LoginRedirect.js";

// This is the inner wrapper of the app. The header is outside of this component and is not part of the scrollable content. This component is responsible for handling the top padding of the inner wrapper which is used to offset the fixed header to prevent it from overlapping the content
const AppInnerWrapperStyled = styled.div`
  overflow: scroll;
  height: 100vh;
  overflow-x: hidden;
  #main-container-wrapper {
    height: 100vh;
  }
`;

const AppWrapper = () => {
  // create hook for token where the default state is the localstorage token

  const [token, setToken] = useState(localStorage.getItem("jwt") || null);

  const isDesktop = useMediaQuery({
    query: `${GlobalVariables.device.laptop}`,
  }); //We will need this to disable the forced padding on the inner wrapper on desktop
  const [PaddingBasedOnScreenSizeState, setPadding] = useState(0);
  console.log("app wrapper rendered");

  // Get the height of the header once loaded and then set the top padding of the inner app wrapper to that
  useEffect(() => {
    const headerHeight = document.getElementById("header-wrapper").offsetHeight;
    const setPaddingBasedOnScreenSize = () => {
      if (isDesktop) {
        return 0;
      } else {
        return headerHeight;
      }
    };
    setPadding(setPaddingBasedOnScreenSize);
  }, [isDesktop, PaddingBasedOnScreenSizeState]);

  return (
    <AppInnerWrapperStyled id="app-inner-wrapper">
      <Header token={token} />
      <div
        id="main-container-wrapper"
        style={{ paddingTop: PaddingBasedOnScreenSizeState + "px" }}
      >
        <Routes>
          <Route path="/" element={token ? <Home /> : <SignIn />} />

          <Route path="/design" element={<Page2 />} />
          <Route
            path="/connect/auth0/redirect"
            element={<LoginRedirect setToken={setToken} />}
          />
        </Routes>
      </div>
    </AppInnerWrapperStyled>
  );
};

export default AppWrapper;
