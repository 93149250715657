import "./App.css";
import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import ScrollToTopOnRouteChange from "./Components/ScrollToTop";
import styled from "styled-components/macro";
import AppInnerWrapper from "./Components/AppInnerWrapper"; // This acts as an inner-wrapper for the app, it contains the header and the routes and is only needed because of the fixed nature of the dashboard design
import * as GlobalVariables from "./Styles/GlobalVariables";

//NOTE - Styling is done here because the nature of this dashboard means the background is always present on every page
//IMAGES
import background from "./Images/dashboard-bg-small.jpg";
import backgroundMedium from "./Images/dashboard-bg-medium.jpg";
import backgroundLarge from "./Images/dashboard-bg-large.jpg";

const AppWrapperStyled = styled.div`
  height: 100vh;
  max-height: 100vh;
  overflow: hidden;
  background: url(${background}) no-repeat top center fixed;
  background-size: cover;
  @media ${GlobalVariables.device.laptop} {
    background: url(${backgroundLarge}) no-repeat center;
  }
`;

function App() {
  console.log("app rendered");

  return (
    <div>
      <ScrollToTopOnRouteChange />
      <AppWrapperStyled>
        <AppInnerWrapper></AppInnerWrapper>
      </AppWrapperStyled>
    </div>
  );
}

export default App;
