import React from "react";
import styled from "styled-components/macro";
import * as GlobalVariables from "../Styles/GlobalVariables";

const CardsStyled = styled.a`
  cursor: pointer;
  width: 14.3em;
  background: white;
  border-radius: 16px;
  overflow: hidden;
  text-align: center;
  margin-bottom: 2em;
  img {
    width: 100%;
    height: 6.4em;
    object-fit: cover;

    display: block;
  }
  h2 {
    padding: 1.6em 0em;
  }
`;

const Cards = (props) => {
  return (
    <CardsStyled target="_blank" href={props.href}>
      <img src={props.img} alt={props.title + " logo"} />
      <h2>{props.title}</h2>
    </CardsStyled>
  );
};

export default Cards;
