import React, { useEffect, useState } from "react";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import loader from "../Images/loader.svg";
import styled from "styled-components/macro";
const backendUrl = process.env.REACT_APP_BACKEND_URL;

const LoginRedirectWrapper = styled.div`
  display: flex;
  justify-content: center;
`;

const LoginRedirect = (props) => {
  console.log("login redirect loaded");
  const [text, setText] = useState("Loading...");
  const location = useLocation();
  const params = useParams();
  const history = useNavigate();

  const setToken = props.setToken;

  useEffect(() => {
    // Successfully logged with the provider
    // Now logging with strapi by using the access_token (given by the provider) in props.location.search
    fetch(`${backendUrl}/api/auth/auth0/callback${location.search}`)
      .then((res) => {
        if (res.status !== 200) {
          throw new Error(`Couldn't login to Strapi. Status: ${res.status}`);
        }
        console.log("Fetch p1 complete");
        return res;
      })
      .then((res) => res.json())
      .then((res) => {
        console.log("Fetch p2 complete");
        // Successfully logged with Strapi
        // Now saving the jwt to use it for future authenticated requests to Strapi
        localStorage.setItem("jwt", res.jwt);
        localStorage.setItem("username", res.user.username);
        setToken(res.jwt);
        history("/"); // Redirect to homepage
      })
      .catch((err) => {
        console.log(err);
        setText(
          "An error occurred, please try again or contact us, re-directing to the homepage in 3 seconds"
        );
        // function that runs after 3 seconds to redirect the user to the homepage
        setTimeout(() => {
          history("/");
        }, 3000);
      });
  }, [history, location.search, params.providerName]);

  return (
    <LoginRedirectWrapper>
      <img src={loader} alt="loader" />
    </LoginRedirectWrapper>
  );
};

export default LoginRedirect;
