import React from "react";
import styled from "styled-components/macro";
import * as GlobalVariables from "../Styles/GlobalVariables";

const CardsContainerStyled = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  @media ${GlobalVariables.device.laptop} {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-column-gap: 20px;
    grid-row-gap: 0px;
    grid-template-rows: auto;
    justify-items: center;
  }
`;

const CardsContainer = (props) => {
  return (
    <CardsContainerStyled>
      {
        props.children /* this injects the content from wherever its used as a HOC, that way you can call the button component and put your own text */
      }
    </CardsContainerStyled>
  );
};

export default CardsContainer;
