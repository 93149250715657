import React from "react";
import { useState, useEffect } from "react";
import styled from "styled-components/macro";
import * as GlobalVariables from "../Styles/GlobalVariables";
import MainSectionWrapper from "../Components/MainSectionWrapper";
import { useNavigate } from "react-router-dom";

//IMAGES

//Components
import Button from "../Components/Button";
import CardsContainer from "../Components/CardsContainer";
import Cards from "../Components/Cards";

const HomeWrapper = styled.div`
  .intro-section {
    img {
      width: 100%;
    }
  }

  .cards-section {
    text-align: center;
    > h2 {
      margin-bottom: 2em;
    }
  }
`;

const OverviewContainer = styled.div`
  text-align: center;
  margin-bottom: 4em;
  h2 {
    margin-bottom: 1em;
  }
`;

const Home = () => {
  const [cardData, setCardData] = React.useState([]);
  const navigate = useNavigate();
  const [isLogged, setIsLogged] = useState(!!localStorage.getItem("jwt"));

  function getCards() {
    fetch(`${process.env.REACT_APP_BACKEND}api/cards?populate=*`)
      .then((res) => res.json())
      .then((card) => {
        setCardData(card.data);
        console.log(cardData);
      });
  }
  useEffect(() => {
    //Run the getCards function when the page loads
    getCards();
  }, []);
  return (
    <HomeWrapper>
      <MainSectionWrapper>
        <OverviewContainer className="outer-grid">
          <h2>Current Overview:</h2>
          <h1>Using AI for design inspiration is dope!</h1>
        </OverviewContainer>

        <div className="cards-section outer-grid desktop-inner-grid">
          <h2>Useful stuff</h2>
          <CardsContainer>
            {cardData.map((card, i) => {
              let cardTitle = card.attributes.Title;

              let cardImgURL = card.attributes.CoverImage.data.attributes.url;
              let URL = card.attributes.URL;
              const processedImgURL = cardImgURL.replace(/^\//, "");
              // console.log(processedImgURL);
              return (
                <Cards
                  href={URL}
                  title={cardTitle}
                  img={`${processedImgURL}`}
                  key={URL}
                />
              );
            })}
          </CardsContainer>
        </div>
      </MainSectionWrapper>
    </HomeWrapper>
  );
};

export default Home;
