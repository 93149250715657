import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Squash as Hamburger } from "hamburger-react"; // Animated hamburger icon
import styled from "styled-components/macro";
import { useMediaQuery } from "react-responsive";
import GlobalStyles from "../Styles/GlobalStyles";
import * as GlobalVariables from "../Styles/GlobalVariables";

import Button from "../Components/Button";
import Logo from "../Images/logo-white.svg";

const logoutURLRedirect = process.env.REACT_APP_LOGOUT_URL_REDIRECT;

// The mobile menu container is both offset by a negative margin AND has 0 opacity, that way it is not visible and does not take up space on the page
const HeaderWrapper = styled.div`
  background-color: transparent;
  z-index: 99;
  transition: background-color 0.2s ease-out;
  color: white;
  width: 100%;
  position: fixed;
  // This is the outer container needed to have a smoothly working expandable mobile menu, this contains only the top parts
  @media ${GlobalVariables.device.laptop} {
    position: relative;
  }
  .top-container {
    background: rgba(0, 0, 0, 0);
    transition: all 0.2s;
    &.open {
      background: rgba(0, 0, 0, 1) !important;
    }
    .inner-container {
      display: flex;
      justify-content: space-between;
      align-items: center;

      z-index: 3;
      position: relative;

      @media ${GlobalVariables.device.laptop} {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-template-rows: 1fr;
        grid-column-gap: 0px;
        grid-row-gap: 0px;
      }
    }
  }

  .left-column {
    .logo {
      width: 104px;
      @media ${GlobalVariables.device.laptop} {
        width: 9em;
      }
    }
  }

  // Hide the links on mobile and then show them on larger screens while hiding the button
  .center-column {
    .button {
      display: none;
    }

    @media ${GlobalVariables.device.laptop} {
      display: flex;
      align-items: center;
      justify-content: center;
      .button {
        margin-top: 0;
        margin-right: 1em;
        &:last-of-type {
          margin-right: 0;
        }
      }
      .button {
        display: inline-block;
      }

      .hamburger-react {
        display: none;
      }
    }
  }

  .right-column {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    .button {
      margin-top: 0;
    }
  }

  .mobile-menu-container {
    height: 100vh;
    margin-top: -150vh;
    opacity: 0;
    z-index: 2;
    transition: all 0.2s;
    color: white;
    background-color: rgba(0, 0, 0, 0.9);
    padding-top: 20px;
    padding-bottom: 20px;
    position: absolute;
    &.open {
      opacity: 1;
      margin-top: 0;
    }
  }
`;

const Header = (props) => {
  const token = props.token;

  // Define a function to handle the logout process (for the logout button)
  const handleLogout = (event) => {
    const logoutURL = `https://dev-essws78d7y0c40r1.us.auth0.com/v2/logout?client_id=IuiNP6NogmFlm8YuhZV63LgarpIQkbNn&returnTo=${logoutURLRedirect}`;

    event.preventDefault();
    localStorage.removeItem("jwt");
    localStorage.removeItem("username");
    window.location.href = logoutURL;
  };

  // Define state variable to keep track of whether the menu is open or closed
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  // Define state variable to keep track of the background opacity
  const [backgroundOpacity, setScrollTriggerPoint] = useState(false);

  // Define a function to toggle the menu state when the button is clicked
  const handleMenuButtonClick = () => {
    setIsMenuOpen(!isMenuOpen);

    // Toggle the body overflow to prevent scrolling when the menu is open
    isMenuOpen
      ? (document.body.style.overflow = "visible")
      : (document.body.style.overflow = "hidden");
  };

  // Define a media query to check if the screen is desktop size, we will disable stickiness and background change on desktop
  const isDesktop = useMediaQuery({
    query: `${GlobalVariables.device.laptop}`,
  });

  // Define the menu link tags as a function so each one gets an OnClick (to close the mobile menu when a link has been pressed)
  function MobileLink({ to, children }) {
    return (
      <Button>
        <Link to={to} onClick={handleMenuButtonClick}>
          {children}
        </Link>
      </Button>
    );
  }

  // Use an effect to update the background opacity when the user scrolls
  useEffect(() => {
    const AppInnerWrapper = document.getElementById("app-inner-wrapper"); // Get the element that will be used as a scroll listener

    function handleScroll() {
      // Define a scroll event handler function to calculate the opacity based on the current scroll position

      const AppInnerWrapperScrollPosition = AppInnerWrapper.scrollTop; // Get the current scroll position of the AppWrapper, remember, we can't use window here because the AppWrapper is the element that scrolls, not the window
      const ScrollTriggerPoint = AppInnerWrapperScrollPosition > 100; // Define the scroll trigger point as a boolean (true/false) based on the current scroll position

      setScrollTriggerPoint(ScrollTriggerPoint);
    }

    // Add the scroll event listener and return a cleanup function to remove the listener when the component unmounts
    AppInnerWrapper.addEventListener("scroll", handleScroll);
    return () => {
      AppInnerWrapper.removeEventListener("scroll", handleScroll);
    };
  }, []);

  // Define a style object for the background with the calculated opacity

  const setBackgroundStlyeIfMobile = () => {
    if (!isDesktop) {
      return {
        backgroundColor: `rgba(0, 24, 53, ${backgroundOpacity ? "0.65" : "0"})`,
      };
    } else {
      return { backgroundColor: `rgba(0, 0, 0,0` };
    }
  };

  return (
    <HeaderWrapper id="header-wrapper">
      <div
        className={`top-container ${
          isMenuOpen ? "open" : ""
        } vertical-padding-small`}
        style={setBackgroundStlyeIfMobile()}
      >
        <div className="outer-grid inner-container">
          <div className={`left-column`}>
            <img className="logo" src={Logo} alt="logo" />
          </div>

          <div className="center-column">
            {
              // If the user is logged in, render the header
            }
            {token ? (
              <div>
                <Button>
                  <Link to="/">Dashboard</Link>
                </Button>
                <Button>
                  <Link to="/dev">Dev</Link>
                </Button>
                <Button>
                  <Link to="/design">Design</Link>
                </Button>
                {/* Render the menu button*/}
                <Hamburger
                  toggled={isMenuOpen}
                  toggle={handleMenuButtonClick}
                />
              </div>
            ) : null}
          </div>

          <div className="right-column">
            {
              // If the user is logged in, render a logout button
              token ? <Button onClick={handleLogout}>Logout</Button> : null
            }
          </div>
        </div>
      </div>

      {/* If the menu is open, render a list of links */}
      <div
        className={`mobile-menu-container ${
          isMenuOpen ? "open" : ""
        } outer-grid`}
      >
        <MobileLink to="/">Dashboard</MobileLink>
        <MobileLink to="/dev">Dev</MobileLink>
        <MobileLink to="/design">Design</MobileLink>
      </div>

      <GlobalStyles />
    </HeaderWrapper>
  );
};

export default Header;
