import React, { Fragment } from "react";
import styled from "styled-components/macro";
import Button from "../Components/Button";
const backendUrl = process.env.REACT_APP_BACKEND_URL;

const SignInWrapper = styled.div`
  display: flex;

  align-items: center;

  flex-direction: column;
  h1 {
    display: block;
  }
`;

const Modal = styled.div`
  position: fixed;
  z-index: 100;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;

  background-color: rgba(0, 0, 0, 0.5);

  display: flex;

  align-items: center;

  justify-content: center;

  .modal-content {
    background-color: rgba(0, 0, 0, 0.8);
    max-width: 500px;
    padding: 1rem;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    color: white;
    align-items: center;
    padding: 3em;
  }
`;

const SignIn = () => {
  const LoginButton = (props) => (
    <a href={`${backendUrl}/api/connect/auth0`}>
      <Button>Login</Button>
    </a>
  );

  return (
    <SignInWrapper>
      <Modal>
        <div className="modal-content">
          <h1>Sign In</h1>

          <LoginButton />
        </div>
      </Modal>
    </SignInWrapper>
  );
};

export default SignIn;
