import React from "react";
import styled from "styled-components/macro";
import * as GlobalVariables from "../Styles/GlobalVariables";

const MainSectionWrapperStyled = styled.div`
  height: 100vh;
  padding-top: 2em;
`;

const MainContainerStyled = styled.div``;

const MainSectionWrapper = (props) => {
  return (
    <MainSectionWrapperStyled>
      <MainContainerStyled>
        {
          props.children /* this injects the content from wherever its used as a HOC, that way you can call the button component and put your own text */
        }
      </MainContainerStyled>
    </MainSectionWrapperStyled>
  );
};

export default MainSectionWrapper;
